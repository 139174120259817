.headingLevelOne {
  border-bottom: 1px solid #cbd3d7;
  font-size: 24px; /* NOTE: 運用を間違えても見た目がおかしくならないようあえてh2と同じサイズを指定*/
  font-weight: bold;
  line-height: 1.2;
  margin-top: 36px;
  padding-bottom: 8px;
  padding-left: 8px;
  position: relative;

  @media (max-width: 640px) {
    font-size: 20px; /* NOTE: 運用を間違えても見た目がおかしくならないようあえてh2と同じサイズを指定*/
  }

  &::after {
    background-color: #2cc09c;
    content: '';
    height: calc(100% - 8px);
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.headingLevelTow {
  border-bottom: 1px solid #cbd3d7;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  margin-top: 36px;
  padding-bottom: 8px;
  padding-left: 8px;
  position: relative;

  @media (max-width: 640px) {
    font-size: 20px;
  }

  &::after {
    background-color: #2cc09c;
    content: '';
    height: calc(100% - 8px);
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.headingLevelThree {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

.image {
  height: auto;
  max-width: 100%;
}

/* 直接要素向けのスタイル*/
.content {
  font-size: 14px;
  margin-top: 40px;
}

.content *:first-child {
  margin-top: 0;
}

.content > h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 2.8rem 0 1rem;
}

.content > h5 {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 2.8rem 0 1rem;
}

.content > p {
  line-height: 1.8;
  margin: 1.5rem 0 0.5rem;
}

.content > pre {
  overflow-x: auto;
}

.content pre > code {
  background-color: var(--color-bg-code);
  display: block;
  overflow-x: auto;
  padding: 16px;
}

.content div[data-filename]::before {
  background-color: var(--color-bg-sub);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  content: attr(data-filename);
  display: inline-block;
  font-family: menlo, inconsolata, monospace;
  font-size: 0.8rem;
  margin: 1em 0 0;
  padding: 4px 16px;
}

.content ul,
.content ol {
  margin: 0.5rem 0 0.5rem 2rem;
}

.content > ul,
.content > ol {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.content ul > li {
  list-style: disc;
  margin: 0.5rem 0;
}

.content ol > li {
  list-style: decimal;
}

.content ul li {
  margin: 0.5rem 0;
}

.content ol li {
  margin: 0.5rem 0;
}

.content a {
  color: #2cc09c;
  text-decoration: underline;
}

.content > figure {
  margin: 2rem 0;
}

.content > figure img {
  height: auto;
  max-width: 100%;
}

.content > figure figcaption {
  color: var(--color-text-sub);
  font-size: 0.8rem;
}

.content > strong {
  font-weight: bold;
}

.content > blockquote {
  border-left: 4px solid var(--color-border);
  font-style: italic;
  margin: 2rem 0;
  padding-left: 1rem;
}

.content > hr {
  background-color: var(--color-border);
  border: none;
  height: 1px;
  margin: 4rem 0;
}

.content > table {
  margin: 2rem 0;
  width: 100%;
}

.content > table th {
  background-color: var(--color-bg-sub);
  padding: 1rem;
  text-align: left;
}

.content > table td {
  padding: 1rem;
  text-align: left;
}

@media (max-width: 640px) {
  .content {
    width: 100%;
  }

  .content > h1 {
    border-bottom: 1px solid var(--color-border);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2.8rem 0 1rem;
  }

  .content > h2 {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 2.8em 0 1rem;
  }

  .content > h3 {
    font-size: 1.3rem;
    font-weight: bold;
    margin: 2.8rem 0 1rem;
  }

  .content > h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 2.8rem 0 1rem;
  }

  .content > h5 {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 2.8rem 0 1rem;
  }
}
