.container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 24px;
}
.list {
  margin: 0 4px;
}
.item {
  align-items: center;
  border-radius: var(--border-radius);
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
}
.current {
  background-color: var(--color-button-primary);
  color: var(--color-text-unpainted);
}
