.blogDetailContainer {
  background-color: #e2efef;
  padding: 68px 0;

  @media (max-width: 640px) {
    padding: 57px 16px;
  }

  .inner {
    margin: 0 auto;
    width: 968px;

    @media (max-width: 968px) {
      width: 768px;
    }

    @media (max-width: 768px) {
      width: 640px;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }
}

.contentSection {
  background-color: var(--color-bg-main);
  border-radius: 4px;
  margin-top: 75px;

  @media (max-width: 640px) {
    margin-top: 47px;
  }

  &.--first {
    margin-top: 28px;

    @media (max-width: 640px) {
      margin-top: 15px;
    }
  }

  .inner {
    margin: 0 auto;
    padding: 60px 10px;
    width: 648px;

    @media (max-width: 968px) {
      width: 548px;
    }

    @media (max-width: 768px) {
      width: 448px;
    }

    @media (max-width: 640px) {
      padding: 14px;
      width: 100%;
    }

    .description {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .category {
        border: 1px solid #36897f;
        border-radius: 20px;
        color: #36897f;
        display: inline-block;
        font-size: 10px;
        line-height: 1;
        padding: 5px 14px;
      }

      .date {
        align-items: center;
        display: flex;
        margin-top: auto;

        .text {
          color: #19292d;
          font-size: 14px;
          margin-left: 4px;
        }
      }
    }

    .heading {
      font-size: 30px;
      line-height: 1.8;
      margin-top: 32px;
      word-break: break-all;

      @media (max-width: 640px) {
        font-size: 18px;
        line-height: 1.4;
      }
    }

    .thumbnail {
      height: auto;
      margin-top: 24px;
      object-fit: cover;
      width: 100%;
    }

    //書いた人
    .blogHeadingWrapper {
      background-color: #e2efef;
      border-radius: 8px;
      padding: 24px 0;
    }

    .editorSectionPC {
      display: flex;
      margin-top: 40px;

      .image {
        border-radius: 100px;
        object-fit: cover;
      }

      .editorDescription {
        margin-left: 50px;

        .role {
          color: #9b9f9c;
          display: block;
          font-size: 14px;
          line-height: 1.2;
        }

        .name {
          display: block;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.2;
          margin-top: 4px;
        }

        .text {
          font-size: 14px;
          margin-top: 8px;
        }
      }
    }

    .editorSectionSP {
      margin-top: 20px;

      .header {
        align-items: center;
        display: flex;

        .image {
          border-radius: 100px;
          object-fit: cover;
        }

        .editorDescription {
          margin-left: 20px;

          .role {
            color: #9b9f9c;
            display: block;
            font-size: 12px;
            line-height: 1.2;
          }

          .name {
            display: block;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.2;
            margin-top: 4px;
          }
        }
      }
      /* stylelint-disable-next-line no-descending-specificity*/
      .text {
        font-size: 14px;
        margin-top: 8px;
        padding-bottom: 8px;
      }
    }

    //資料請求
    .blogFormWrapper {
      margin-top: 40px;
    }
  }
}

.recommendSection {
  margin-top: 92px;
  padding-bottom: 109px;

  .cardContainer {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 35px;

    @media (max-width: 968px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 640px) {
      display: block;
      margin-top: 37px;
    }

    .cardWrapper {
      @media (max-width: 640px) {
        margin-top: 20px;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  .buttonWrapper {
    margin: 0 auto;
    margin-top: 69px;
    width: 560px;

    @media (max-width: 640px) {
      padding: 14px;
      width: 286px;
    }
  }
}
