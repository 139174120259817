.contentSection {
  background-color: var(--color-bg-main);
  border-radius: 4px;
  margin-top: 28px;

  @media (max-width: 640px) {
    margin-top: 15px;
  }

  .inner {
    margin: 0 auto;
    padding: 60px 10px;
    width: 648px;

    @media (max-width: 968px) {
      width: 548px;
    }

    @media (max-width: 768px) {
      width: 448px;
    }

    @media (max-width: 640px) {
      padding: 14px;
      width: 100%;
    }

    .description {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .category {
        border: 1px solid #36897f;
        border-radius: 20px;
        color: #36897f;
        display: inline-block;
        font-size: 10px;
        line-height: 1;
        padding: 5px 14px;
      }

      .date {
        align-items: center;
        display: flex;
        margin-top: auto;

        .text {
          color: #19292d;
          font-size: 14px;
          margin-left: 4px;
        }
      }
    }

    .heading {
      font-size: 30px;
      line-height: 1.8;
      margin-top: 32px;
      word-break: break-all;

      @media (max-width: 640px) {
        font-size: 18px;
        line-height: 1.4;
      }
    }

    .thumbnail {
      height: auto;
      margin-top: 24px;
      object-fit: cover;
      width: 100%;
    }
  }
}
