.top {
  align-items: center;
  /*background-color: rgba(0, 0, 0, 0.5); 薄いBlack*/
  color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width:100%;
  height: 800px;
  /* padding: 200px 0; */

  position: relative;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.description {
  text-align: center;
  
}
.top_text{
  z-index: 1;
}
/* .bgimg {
  align-items: center;
  display: flex;
  height: 600px;
  justify-content: center;
  object-fit: cover;
  object-position: right;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
} */

.video {
  align-items: center;
  display: flex;
  justify-content: center;
  object-fit: cover;
  object-position: right;
  position: absolute;
  right: 0;
  min-width: 100%;
  top: 0;
  z-index: -2;
}

.news {
  background-color: #fff;
  border-radius: var(--border-radius);
  margin: -40px auto 0;
  padding: 24px 40px;
  position: relative;
  width: 840px;
}

.newsTitle {
  font-size: 1.5rem;
}

.newsLink {
  bottom: 10px;
  position: absolute;
  right: 10px;
}

.section {
  margin: 0 auto;
  padding: 100px 0;
  width: 920px;
}

.sectionTitleEn {
  font-size: 3rem;
  font-weight: bold;
}

.sectionTitleJa {
  align-items: center;
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.sectionTitleJa::before {
  background-color: var(--color-text-main);
  content: '';
  display: block;
  height: 1px;
  width: 40px;
}

.sectionDescription {
  margin-bottom: 40px;
}

.horizontal {
  align-items: flex-start;
  display: flex;
  gap: 80px;
  justify-content: space-between;
}

.businessImg {
  border-radius: var(--border-radius);
  height: auto;
  width: 400px;
}

.button {
  background: url('/arrow-right.svg') no-repeat right 20px center, var(--color-button-primary);
  border-radius: var(--border-radius);
  color: var(--color-text-unpainted);
  display: block;
  padding: 20px 40px;
  width: 300px;
}

.aboutus {
  background-color: #fff;
}

.aboutusImg {
  border-radius: var(--border-radius);
  height: auto;
  margin-left: -60%;
  width: 100%;
}

.info {
  border-bottom: 1px solid var(--color-border);
  display: flex;
  padding: 20px 0;
}
.companyButton {
  margin-top: 20px;
}

.infoTitle {
  font-weight: bold;
  width: 100px;
}

.infoDescription {
  flex: 1;
}

.hiringImg {
  border-radius: var(--border-radius);
  height: auto;
  width: 480px;
}

@media (max-width: 1000px) {
  .news {
    width: calc(100% - 160px);
  }

  .section {
    width: calc(100% - 80px);
  }
}

@media (max-width: 640px) {
  .top {
    padding: 120px 16px;
  }
  .title {
    font-size: 2rem;
    text-align: left;
  }

  .description {
    font-size: 0.9rem;
    text-align: center;
    text-align: left;
  }

  .video {
    align-items: center;
    display: flex;
    justify-content: center;
    object-fit: cover;
    object-position: 5% 50%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  /* .bgimg {
    align-items: center;
    display: flex;
    height: 600px;
    justify-content: center;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: -1;
  } */

  .news {
    padding: 16px 24px;
    width: calc(100% - 32px);
  }

  .newsLink {
    bottom: auto;
    margin-top: 16px;
    position: relative;
    right: auto;
  }

  .section {
    margin: auto;
    padding: 80px 16px;
    width: 100%;
  }

  .sectionTitleEn {
    font-size: 2.4rem;
    font-weight: bold;
  }

  .sectionTitleJa {
    align-items: center;
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }

  .sectionTitleJa::before {
    background-color: var(--color-text-main);
    content: '';
    display: block;
    height: 1px;
    width: 40px;
  }

  .sectionDescription {
    margin-bottom: 40px;
  }

  .horizontal {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }

  .businessImg {
    height: auto;
    width: 100%;
  }

  .aboutusImg {
    height: auto;
    margin-left: 0;
    width: 100%;
  }

  .hiringImg {
    height: auto;
    width: 100%;
  }

  .button {
    background: url('/arrow-right.svg') no-repeat right 20px center, var(--color-button-primary);
    border-radius: var(--border-radius);
    color: var(--color-text-unpainted);
    display: block;
    padding: 16px 24px;
    width: 100%;
  }
}
